<template>
  <div class="c-app flex-row align-items-center register">
    <CContainer>

      <CRow class="justify-content-center">
        <CCol md="6">
            <div class="logo"><img src="@/assets/images/cropped-favicon_las-1.png"></div>
        </CCol>
      </CRow>
      <CRow class="justify-content-center">

        <CCol md="6">
          <CCardGroup>
            <CCard>
              <CCardBody v-if="!register_success">
                    <div class="row">
                        <div class="col-md-12">
                            <h2>Cadastre-se</h2>   
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 form-group">
                            <!-- <label for="username">E-mail</label> -->
                            <input
                                type="text"
                                class="form-control"
                                id="email"
                                v-model="register.username"
                                placeholder="Nome de usuário"
                                autocomplete="off"
                            />
                        </div>
                        <div class="col-md-12 form-group">
                            <!-- <label for="username">E-mail</label> -->
                            <input
                                type="text"
                                class="form-control"
                                id="email"
                                v-model="register.email"
                                placeholder="Email"
                                autocomplete="off"
                            />
                        </div>
                        <div class="col-md-12 form-group">
                            <!-- <label for="username">E-mail</label> -->
                            <input
                                type="text"
                                class="form-control"
                                id="telefone"
                                v-model="register.telefone"
                                placeholder="Nº de telefone"
                                autocomplete="off"
                            />
                        </div>
                    
                        <div class="col-md-12 form-group">
                            <div class="row">
                                <div class="col-md-6">
                                    <input
                                        type="password"
                                        class="form-control"
                                        id="password"
                                        v-model="register.password"
                                        placeholder="Senha"
                                        autocomplete="off"
                                        @blur="validatePassword()"
                                    />
                                </div>
                                <div class="col-md-6">
                                    <input
                                        type="password"
                                        class="form-control"
                                        id="confirmpassword"
                                        v-model="register.confirmpassword"
                                        placeholder="Confirmar senha"
                                        autocomplete="off"
                                        @blur="validatePassword()"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <small style="color:red;" v-if="wrongpassword === true">As senhas não coincidem</small>
                                </div>
                            </div>
                            <!-- <label for="username">E-mail</label> -->
                            
                        </div>

                        <div class="col-md-12 form-group">
                            <label for="username">Classe:</label>
                            <select v-model="register.role" class="form-control" id="roles" autocomplete="off" @change="changeUser()">
                                <option v-for="(rol,key) in tipouser" :key="key" :value="rol"
                                >{{rol}}</option>
                            </select>
                           
                        </div>    
                        <template v-if="register.role == 'Profissionais de Saúde'">
                        <div class="col-md-12 form-group">                 
                            <div class="row">
                                <div class="col-md-6">
                                    <input
                                    type="text"
                                    class="form-control"
                                    id="user_prof-nome"
                                    v-model="user_prof.nome"
                                    placeholder="Nome"
                                    autocomplete="off"
                                    />
                                </div> 
                                <div class="col-md-6">
                                    <input
                                    type="text"
                                    class="form-control"
                                    id="user_prof-sobrenome"
                                    v-model="user_prof.sobrenome"
                                    placeholder="Sobrenome"
                                    autocomplete="off"
                                    />
                                </div>  
                            </div>                          
                        </div>
                        <div class="col-md-12 form-group">                 
                            <div class="row">
                                <div class="col-md-6">
                                    <input
                                    type="text"
                                    class="form-control"
                                    id="user_prof-tipodocumento"
                                    v-model="user_prof.tipo_documento"
                                    placeholder="Tipo de DOC (CRM, CRO...)"
                                    autocomplete="off"
                                    />
                                </div> 
                                <div class="col-md-6">
                                    <input
                                    type="text"
                                    class="form-control"
                                    id="user_prof-numerodocumento"
                                    v-model="user_prof.numero_documento"
                                    placeholder="Nº DOC"
                                    autocomplete="off"
                                    />
                                </div>  
                            </div>                           
                        </div> 
                        <div class="col-md-12 form-group">                 
                            <div class="row">
                                <div class="col-md-4">
                                    <input
                                    type="text"
                                    class="form-control"
                                    id="user_prof-cep"
                                    v-model="user_prof.cep"
                                    placeholder="CEP"
                                    autocomplete="off"
                                    />
                                </div> 
                                <div class="col-md-6">
                                    <input
                                    type="text"
                                    class="form-control"
                                    id="user_prof-numerodocumento"
                                    v-model="user_prof.logradouro"
                                    placeholder="Endereço"
                                    autocomplete="off"
                                    />
                                </div> 
                                <div class="col-md-2">
                                    <input
                                    type="text"
                                    class="form-control"
                                    id="user_prof-numero"
                                    v-model="user_prof.numero"
                                    placeholder="Nº"
                                    autocomplete="off"
                                    />
                                </div>  
                            </div>                           
                        </div>
                           <div class="col-md-12 form-group">                 
                            <div class="row">
                                <div class="col-md-4">
                                    <input
                                    type="text"
                                    class="form-control"
                                    id="user_prof-bairro"
                                    v-model="user_prof.bairro"
                                    placeholder="Bairro"
                                    autocomplete="off"
                                    />
                                </div> 
                                <div class="col-md-4">
                                    <input
                                    type="text"
                                    class="form-control"
                                    id="user_prof-cidade"
                                    v-model="user_prof.cidade"
                                    placeholder="Cidade"
                                    autocomplete="off"
                                    />
                                </div> 
                                <div class="col-md-4">
                                    <input
                                    type="text"
                                    class="form-control"
                                    id="user_prof-estado"
                                    v-model="user_prof.estado"
                                    placeholder="Estado"
                                    autocomplete="off"
                                    />
                                </div>  
                            </div>                           
                        </div>                            
                        </template>
                        <template v-if="register.role == 'Distribuidores'">
                        <div class="col-md-12 form-group">                 
                            <div class="row">
                                <div class="col-md-12">
                                    <input
                                    type="text"
                                    class="form-control"
                                    id="user_dist-razao-social"
                                    v-model="user_dist.razao_social"
                                    placeholder="Razão social"
                                    autocomplete="off"
                                    />
                                </div> 

                            </div>                          
                        </div>
                        <div class="col-md-12 form-group">                 
                            <div class="row">
                                <div class="col-md-12">
                                    <input
                                    type="text"
                                    class="form-control"
                                    id="user_dist-cnpj"
                                    v-model="user_dist.cnpj"
                                    placeholder="CNPJ"
                                    autocomplete="off"
                                    />
                                </div> 

                            </div>                          
                        </div>
                        <div class="col-md-12 form-group">                 
                            <div class="row">
                                <div class="col-md-12">
                                    <input
                                    type="text"
                                    class="form-control"
                                    id="user_dist-inscricao-estadual"
                                    v-model="user_dist.inscricao_estadual"
                                    placeholder="Inscrição estadual"
                                    autocomplete="off"
                                    />
                                </div> 

                            </div>                          
                        </div>

                        <div class="col-md-12 form-group">                 
                            <div class="row">
                                <div class="col-md-4">
                                    <input
                                    type="text"
                                    class="form-control"
                                    id="user_dist-cep"
                                    v-model="user_dist.cep"
                                    placeholder="CEP"
                                    autocomplete="off"
                                    />
                                </div> 
                                <div class="col-md-6">
                                    <input
                                    type="text"
                                    class="form-control"
                                    id="user_dist-logradouro"
                                    v-model="user_dist.logradouro"
                                    placeholder="Endereço"
                                    autocomplete="off"
                                    />
                                </div> 
                                <div class="col-md-2">
                                    <input
                                    type="text"
                                    class="form-control"
                                    id="user_dist-numero"
                                    v-model="user_dist.numero"
                                    placeholder="Nº"
                                    autocomplete="off"
                                    />
                                </div>  
                            </div>                           
                        </div>
                           <div class="col-md-12 form-group">                 
                            <div class="row">
                                <div class="col-md-4">
                                    <input
                                    type="text"
                                    class="form-control"
                                    id="user_dist-bairro"
                                    v-model="user_dist.bairro"
                                    placeholder="Bairro"
                                    autocomplete="off"
                                    />
                                </div> 
                                <div class="col-md-4">
                                    <input
                                    type="text"
                                    class="form-control"
                                    id="user_dist-cidade"
                                    v-model="user_dist.cidade"
                                    placeholder="Cidade"
                                    autocomplete="off"
                                    />
                                </div> 
                                <div class="col-md-4">
                                    <input
                                    type="text"
                                    class="form-control"
                                    id="user_dist-estado"
                                    v-model="user_dist.estado"
                                    placeholder="Estado"
                                    autocomplete="off"
                                    />
                                </div>  
                            </div>                           
                        </div>                            
                        </template>
                
                    </div>





                    <button
                        id="handlerSubmit"
                        class="btn btn-primary btn-access mt-2"
                        @click="registro()"
                    >
                        Cadastrar
                    </button>

                     <div style="display:block;text-align:center">
                    <small> Já é cadastrado? <a href="/login">Clique aqui.</a></small> 
                </div>

              </CCardBody>
              <CCardBody v-if="register_success">
                  <div class="row">
                      <div class="col-md-12">
                          <h2>Cadastro realizado com sucesso</h2>
                          <h3>Em breve entraremos em contato!</h3>
                      </div>
                  </div>
              </CCardBody>
            </CCard>
           
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>


<script>

import { RegisterMixin } from './register-mixin'
export default {
  name: 'Register',
  mixins: [RegisterMixin]
}
</script>
<style lang="scss" scoped>

.logo{
    display:flex;
    align-items: center;
    justify-content: center;
    margin-bottom:30px;
    img {
        width:135px;
    }
}
button {
    color:#fff !important;
    width:100%;
}

h2,h3 {
    display:block;
    text-align:center;
    margin-top:20px;
    margin-bottom:20px;
}
</style>
