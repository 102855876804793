import {register_} from '../../services/register.service'

export const RegisterMixin = {
    data: () => ({
        register_success:false,
        wrongpassword:null,
        register: {
            username: '',
            email: '',
            telefone: '',
            password:'',
            confirmpassword:'',
            confirmed:false,
            blocked:true,
            role:'',
            tipo_user:[]
        },
        tipouser:['Profissionais de Saúde','Distribuidores'],
        user_prof:{
            "__component": "tipo.profissionais-da-saude",
            "nome": "",
            "sobrenome": "",
            "tipo_documento": "",
            "numero_documento": "",
            "cep": "",
            "logradouro": "",
            "numero": "",
            "cidade": "",
            "estado": "",
            'bairro':"",
        },
        user_dist:{
            "__component": "tipo.distribuidores",
            "razao_social": "",
            "cnpj": "",
            "inscricao_estadual": "",
            "cep": "",
            "logradouro": "",
            "numero": "",
            "cidade": "",
            "estado": "",
            'bairro':"",
          },
        isLoading: false
    }),

    watch: {

    },
    computed: {
        
       
    },
    methods: {
       async registro() {
        if(this.register.username === '') return this.$toast.error('O nome de usuário não pode ser vazio')
        if(this.register.email === '') return this.$toast.error('O email não pode ser vazio')
        if(this.register.password === '') return this.$toast.error('A senha não pode estar em branco')
        if(this.register.confirmpassword === '') return this.$toast.error('A confirmação de senha não pode estar em branco')
        if(this.register.telefone != this.register.telefone) return this.$toast.error('Insira um número de telefone')
        if(this.register.password != this.register.confirmpassword) return this.$toast.error('As senhas não coincidem')
        if(this.register.tipo_user.length === 0) return this.$toast.error('É preciso escolher uma classe para o cadastro')
        if(this.register.role === 'Profissionais de Saúde') {
          if (this.user_prof.nome === '') return this.$toast.error('O nome de Profissional de saúde não pode ser vazio')
          if (this.user_prof.tipo_documento === '') return this.$toast.error('O tipo de documento não pode ser vazio') 
          if (this.user_prof.numero_documento === '') return this.$toast.error('O número do documento não pode ser vazio') 
          if (this.user_prof.cep === '') return this.$toast.error('O cep não pode ser vazio') 
          if (this.user_prof.logradouro === '') return this.$toast.error('O endereço não pode ser vazio')
          if (this.user_prof.numero === '') return this.$toast.error('O número não pode ser vazio') 
          if (this.user_prof.bairro === '') return this.$toast.error('O bairro deve estar preenchido') 
          if (this.user_prof.cidade === '') return this.$toast.error('A cidade não pode estar vazia') 
          if (this.user_prof.estado === '') return this.$toast.error('O estado deve estar preenchido') 
          
        }
        if(this.register.role === 'Distribuidores') {
         if (this.user_dist.razao_social === '') return this.$toast.error('A razão social deve estar preenchida')
         if (this.user_dist.cnpj === '') return this.$toast.error('O CNPJ de documento não pode ser vazio') 
         if (this.user_dist.cep === '') return this.$toast.error('O cep não pode ser vazio') 
         if (this.user_dist.logradouro === '') return this.$toast.error('O endereço não pode ser vazio')
         if (this.user_dist.numero === '') return this.$toast.error('O número não pode ser vazio') 
         if (this.user_dist.cidade === '') return this.$toast.error('A cidade não pode estar vazia') 
         if (this.user_dist.estado === '') return this.$toast.error('O estado deve estar preenchido') 
         if (this.user_dist.bairro === '') return this.$toast.error('O bairro deve estar preenchido') 
       }
        
       try {
            const {data} = await register_(this.register)
            this.register_success = true

          }
          catch(e) {
             this.$toast.error('Ocorreu um erro ao fazer o cadastro')
          }
       },
       changeUser() {
         if(this.register.role === 'Profissionais de Saúde') this.register.tipo_user = [this.user_prof] 
         else this.register.tipo_user = [this.user_dist]
       },
       validatePassword() {
        if(this.register.password != this.register.confirmpassword) {
          return this.wrongpassword = true
        }
        else {
          this.wrongpassword = false
        }
       },
       validateForm() {
         if(this.register.username === '') return this.$toast.error('O nome de usuário não pode ser vazio')
         if(this.register.email === '') return this.$toast.error('O email não pode ser vazio')
         if(this.register.password === '') return this.$toast.error('A senha não pode estar em branco')
         if(this.register.confirmpassword === '') return this.$toast.error('A senha não pode estar em branco')
         if(this.register.password != this.register.confirmpassword) return this.$toast.error('As senhas não coincidem')

         if(this.role === 'Profissionais de Saúde') {
           if (this.user_prof.nome === '') return this.$toast.error('O nome de Profissional de saúde não pode ser vazio')
           if (this.user_prof.tipo_documento === '') return this.$toast.error('O tipo de documento não pode ser vazio') 
           if (this.user_prof.numero_documento === '') return this.$toast.error('O número do documento não pode ser vazio') 
           if (this.user_prof.cep === '') return this.$toast.error('O cep não pode ser vazio') 
           if (this.user_prof.logradouro === '') return this.$toast.error('O endereço não pode ser vazio')
           if (this.user_prof.numero === '') return this.$toast.error('O número não pode ser vazio') 
           if (this.user_prof.cidade === '') return this.$toast.error('A cidade não pode estar vazia') 
           if (this.user_prof.estado === '') return this.$toast.error('O estado deve estar preenchido') 
           if (this.user_prof.bairro === '') return this.$toast.error('O bairro deve estar preenchido') 
         }
         if(this.role === 'Distribuidores') {
          if (this.user_dist.razao_social === '') return this.$toast.error('A razão social deve estar preenchida')
          if (this.user_dist.cnpj === '') return this.$toast.error('O CNPJ de documento não pode ser vazio') 
          if (this.user_dist.cep === '') return this.$toast.error('O cep não pode ser vazio') 
          if (this.user_dist.logradouro === '') return this.$toast.error('O endereço não pode ser vazio')
          if (this.user_dist.numero === '') return this.$toast.error('O número não pode ser vazio') 
          if (this.user_dist.cidade === '') return this.$toast.error('A cidade não pode estar vazia') 
          if (this.user_dist.estado === '') return this.$toast.error('O estado deve estar preenchido') 
          if (this.user_dist.bairro === '') return this.$toast.error('O bairro deve estar preenchido') 
        }

        return 'success'
       }
       
    }
}
